import React, {useState, useEffect} from 'react';
 
const  Banners = props => {



  return( 
    <div className="banners">
      <img src='/svg/banner.svg' width="100%"/>
    </div>  
  )
}
export default Banners;