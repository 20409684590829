import React from 'react';
import P5Wrapper from 'react-p5-wrapper';
import sketch from './sketch';
import starSketch from './starSketch';
import threeDSketch from  './threeDSketch';
import orthoSketch from  './orthoSketch';

export default class P5Showcase extends React.Component {
  constructor(props){
    super(props);
    this.props = props;

    this.state ={
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({});
  }
  onPointerDown(e){
    console.log('onPointerDown e is ', e);
  }
  onPointerUp(e){
    console.log('onPointerUp e is ', e);
  }
  render() {
    return(
      <div className="p5Showcase">
        Canvas
        <P5Wrapper sketch={sketch} x="200" y="200" diameter="100" />
        <P5Wrapper sketch={starSketch}  />
        <P5Wrapper sketch={threeDSketch}  />
        <P5Wrapper sketch={orthoSketch}  />
      </div>
    );
  }
}

