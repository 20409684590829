import React, {useState, useEffect} from 'react';
import { Stage, Layer, Line } from 'react-konva';
import Konva from 'konva';
import '../../styles/main.scss';
import { v4 as uuidv4 } from 'uuid';


const  KochSnowflake = (props) => {
  const [kochLines, setKochLines] = useState([]);

  useEffect(()=>{
    drawSegment1([],[]);
  },[]);
  const masterScale = window.innerWidth * 0.6;
  const maxDepth = 2;

  const drawSegment1 = () =>{
    //console.log('in drawSegment1')
    const p0 = {x:window.innerWidth * 0.32, y: window.innerHeight * 0.28};
    const p1 = {x:window.innerWidth * 0.68, y: window.innerHeight * 0.28};
    const p2 = {x:p1.x + Math.cos(Math.PI * 2/3) * (p1.x-p0.x), y: p1.y + Math.sin(Math.PI * 2/3) * (p1.x-p0.x)};
    drawKoch(p0, p1, [], maxDepth, drawSegment2)
  }
  const drawSegment2 = (kochLinesTemp, segment) =>{
    //console.log('in drawSegment2 kochLinesTemp is ', kochLinesTemp);
    //console.log('in drawSegment2 segment is ', segment);
    const p0 = {x:window.innerWidth * 0.32, y: window.innerHeight * 0.28}; 
    const p1 = {x:window.innerWidth * 0.68, y: window.innerHeight * 0.28};
    const p2 = {x:p1.x + Math.cos(Math.PI * 2/3) * (p1.x-p0.x), y: p1.y + Math.sin(Math.PI * 2/3) * (p1.x-p0.x)};
    kochLinesTemp.push( {points: segment, color:'red'});
    drawKoch(p1, p2, kochLinesTemp, maxDepth, drawSegment3);
    //setKochLines([...kochLinesTemp]); 
  };
  const drawSegment3 = (kochLinesTemp, segment) =>{
    //console.log('in drawSegment3 kochLinesTemp is ', kochLinesTemp);
   const p0 = {x:window.innerWidth * 0.32, y: window.innerHeight * 0.28};
    const p1 = {x:window.innerWidth * 0.68, y: window.innerHeight * 0.28};
    const p2 = {x:p1.x + Math.cos(Math.PI * 2/3) * (p1.x-p0.x), y: p1.y + Math.sin(Math.PI * 2/3) * (p1.x-p0.x)};
    kochLinesTemp.push( {points: segment, color:'green'});
    drawKoch(p2, p0, kochLinesTemp, maxDepth, finalSegment);
    //setKochLines([...kochLinesTemp]); 
  };
  const finalSegment = (kochLinesTemp, segment) =>{
     kochLinesTemp.push( {points: segment, color:'blue'});
     setKochLines([...kochLinesTemp]);   
  }


  const drawKoch = (p0, p1, kochLinesTemp, depth, nextSegment ) => {   
    const dx = p1.x - p0.x;
    const dy = p1.y - p0.y;
    const dist = Math.sqrt((dx*dx)+(dy*dy));
    const unit = dist/3;
    const angle = Math.atan2(dy,dx);
    const pa = {x:p0.x + Math.cos(angle) * unit, y:p0.y + Math.sin(angle) * unit}
    const pb = {x:pa.x + Math.cos(angle-Math.PI/3) * unit, y:pa.y + Math.sin(angle-Math.PI/3) * unit};
    const pc = {x:p0.x + Math.cos(angle) * unit * 2, y:p0.y + Math.sin(angle) * unit * 2}

    if(depth === 0 ){
      nextSegment(kochLinesTemp, [p0.x, p0.y, pa.x, pa.y, pb.x, pb.y, pc.x, pc.y, p1.x ,p1.y]);
    } else{
      drawKoch(p0, pa, kochLinesTemp, depth-1, nextSegment);
      drawKoch(pa, pb, kochLinesTemp, depth-1, nextSegment);
      drawKoch(pb, pc, kochLinesTemp, depth-1, nextSegment);
      drawKoch(pc, p1, kochLinesTemp, depth-1, nextSegment);
    }
  }
  return (
    <div className="lines canvas" >
      <Stage width={window.innerWidth} height={window.innerHeight-70}  >
          <Layer >
          {console.log('kochLines.length is ' ,kochLines.length)}
          { 
            kochLines.map(kochLine =>{
              return(
                <Line 
                  key={uuidv4()} 
                  x={0}
                  y={0}
                  points={kochLine.points}
                  stroke={kochLine.color}
                  strokeWidth="5"
                />
              )
            })
          }
        </Layer>
      </Stage>
    </div>
  )
}
export default KochSnowflake;