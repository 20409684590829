import React, {useState} from 'react';
import { Stage, Layer, Text } from 'react-konva';
import Konva from 'konva';
import ColoredRect from './coloredRect';
import { v4 as uuidv4 } from 'uuid';
import '../../styles/main.scss';

const  Rectangles = (props) => {
  const [rectangles, setRectangles] = useState([]);

  const handleClick = () =>{
    const tempRectangles = rectangles;
    const newRectangle = {x:Math.random(), y:Math.random(), height:Math.random(), width:Math.random(), color:Konva.Util.getRandomColor()};
    tempRectangles.push(newRectangle);
    setRectangles([...tempRectangles]);
  }
  return (
    <div className="rectangles canvas" onClick={handleClick} onTap="handleClick" >
      <Stage className="stage" width={window.innerWidth} height={window.innerHeight}  >
        <Layer >
          <Text text={`Click on the screen to add random rectangles`} />
          {
            rectangles.map(rectangle =>{
              //console.log(rectangle);
              try{
                return <ColoredRect key={uuidv4()} rectangle={rectangle} handleClick={handleClick} />
              }catch(err){
                console.log('err is ', err);
              }
            })
          }
        </Layer>
      </Stage>
    </div>
  );
}
export default Rectangles;