import React, {useState, useEffect} from 'react';
import { Stage, Layer, Line } from 'react-konva';
import Konva from 'konva';
import '../../styles/main.scss';
import { v4 as uuidv4 } from 'uuid';
 

const  Coast = (props) => {
  const [points, setPoints] = useState([]);

  useEffect(()=>{
    const pointsTemp = [];
    pointsTemp.push({x:0, y:window.innerHeight * 0.5});
    pointsTemp.push({x:window.innerWidth, y:window.innerHeight * 0.5});
    drawCoast(pointsTemp,8, window.innerHeight/2);
  },[]);

  const drawCoast = ( pointsTemp, depth, offset ) => {  
   if(depth === 0 ){
      let finalPoints = [];
      pointsTemp.forEach( point =>{
        finalPoints.push(point.x);
        finalPoints.push(point.y);
      });
      setPoints([...finalPoints]);
    } else{
      const localPoints = [...pointsTemp];
      let newPoints = [];
      for(var i=0; i< localPoints.length-1; i++){
        let p0 = localPoints[i];
        let p1 = localPoints[i+1];
        let pn1 = {x:(p0.x + p1.x)/2, y:(p0.y + p1.y)/2};
        let pn2 = {x:Math.floor(pn1.x + (Math.random() * offset )-offset)  , y:Math.floor(pn1.y + (Math.random() * offset * 2)-offset)};

        const pn2XIndex = pointsTemp.findIndex(point => {return point.x ===p1.x;});
        pointsTemp.splice(pn2XIndex, 0,  pn2);
      }
      drawCoast(pointsTemp, depth -1, offset * .5);
    }
  }
  return (
    <div className="coast canvas" >
      <Stage width={window.innerWidth} height={window.innerHeight-70}  >
          <Layer >
            <Line 
              key={uuidv4()} 
              x={0}
              y={0}
              points={points}
              stroke="#ffff88"
              strokeWidth="5"       
            />
        </Layer>
      </Stage>
    </div>
  )
}
export default Coast;