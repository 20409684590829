
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from './components/home/home';
import About from './components/about/about';
import Banners from './components/banners/banners';
import P5Showcase from './components/p5/p5Showcase';
import Rectangles from './components/canvas/rectangles';
import Lines from './components/canvas/lines';
import Sierpinski from './components/canvas/sierpinski';
import Koch from './components/canvas/koch';
import KochSnowflake from './components/canvas/kochSnowflake';
import SimpleFractal      from './components/canvas/simplefractal';
import Tree      from './components/canvas/tree';
import Forest      from './components/canvas/forest';
import Coast      from './components/canvas/coast';
import Chaos      from './components/canvas/chaos';
import Covid      from './components/covid/covid';
import Menu       from './components/menu/menu';
import 'materialize-css'; // It installs the JS asset only
import 'materialize-css/dist/css/materialize.min.css';
import './styles/main.scss';

const  App = (props) => {


  return (
    <div className="App" >
        <BrowserRouter>
          <div className="main">
            <Route  exact path="/" render={(props) => {
                  return ( <div><Menu /><Home {...props} /></div>)
              }}
            />
            <Route  exact path="/banners" render={(props) => {
                  return ( <div><Menu /><Banners {...props} /></div>)
              }}
            />
            <Route  exact path="/covid" render={(props) => {
                  return ( <div><Menu /><Covid {...props} /></div>)
              }}
            />
            <Route  exact path="/rectangles" render={(props) => {
                  return ( <div><Menu /><Rectangles {...props} /></div>)
              }}
            />
            <Route  exact path="/lines" render={(props) => {
                  return ( <div><Menu /><Lines {...props} /></div>)
              }}
            />
            <Route  exact path="/sierpinski" render={(props) => {
                  return ( <div><Menu /><Sierpinski {...props} /></div>)
              }}
            />
            <Route 
              exact path="/koch" render={(props) => {
                return ( <div><Menu /><Koch {... props} /></div>)
              }}
            />
            <Route 
              exact path="/kochsnowflake" render={(props) => {
                return ( <div><Menu /><KochSnowflake {... props} /></div>)
              }}
            />
            <Route 
              exact path="/simplefractal" render={(props) => {
                return ( <div><Menu /><SimpleFractal {... props} /></div>)
              }}
            />
            <Route 
              exact path="/forest" render={(props) => {
                return ( <div><Menu /><Forest {... props} /></div>)
              }}
            />
            <Route 
              exact path="/coast" render={(props) => {
                return ( <div><Menu /><Coast {... props} /></div>)
              }}
            />
            <Route 
              exact path="/chaos" render={(props) => {
                return ( <div><Menu /><Chaos {... props} /></div>)
              }}
            />
            <Route 
              exact path="/about" render={(props) => {
                return ( <div><Menu /><About {... props} /></div>)
              }}
            />
            <Route 
              exact path="/p5" render={(props) => {
                return ( <div><Menu /><P5Showcase {... props} /></div>)
              }}
            />
          </div>
        </BrowserRouter>
    </div>
  );
}
export default App;
