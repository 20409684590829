import p5 from 'p5';

export default function ThreeDSketch (p) {

  const screenHeight = window.innerHeight-60;
  const screenWidth = window.innerWidth;



  p.setup = function () {
    p.createCanvas(screenWidth, screenHeight, p.WEBGL);

  };
  p.myCustomRedrawAccordingToNewPropsHandler = function (props) {
    console.log('props is ', props);

  };

  p.draw = function () {
    p.background(100);

    p.noStroke();
    p.fill(50);
    p.push();
    p.translate(-275, 175);
    p.rotateY(1.25);
    p.rotateX(-0.9);
    p.box(100);
    p.pop();

    p.noFill();
    p.stroke(255);
    p.push();
    p.translate(500, p.height * 0.35, -200);
    p.sphere(300);
    p.pop();
  }
}