import React from 'react';
import {Navbar, NavItem, Icon, Dropdown } from 'react-materialize';

import { Link } from "react-router-dom";
const Menu  = (props) => {
  //console.log('in menu props is ', props)`;

  return(
    <div className="menu">
      <Navbar
        alignLinks="right"
        brand={<Link to="/">Connected Reasoning</Link>}
        id="mobile-nav"
        menuIcon={<i className="material-icons">menu</i>}
        fixed
      >
        <Link to="/covid">COVID-19 map</Link>
        <Link to="/p5">P5</Link>
        <Link to="/banners">Banners</Link>
        <Dropdown
          id="Dropdown_6"
          options={{
            alignment: 'left',
            autoTrigger: true,
            closeOnClick: true,
            constrainWidth: true,
            container: null,
            coverTrigger: true,   
            hover: false,
            inDuration: 150,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            outDuration: 250
          }}
          trigger={<a href="#!">Shapes{' '}<Icon right>arrow_drop_down</Icon></a>}
        >
          <Link to="/chaos">
            Chaos
          </Link> 
          <Link to="/rectangles">
            Rectangles
          </Link> 
          <Link to="/lines">
            Lines
          </Link> 
          <Link to="/sierpinski">
            Sierpinski
          </Link> 
          <Link to="/koch">
            Koch
          </Link> 
          <Link to="/kochsnowflake">
            Koch Snowflake
          </Link> 
          <Link to="/simplefractal">
            Simple Fractal
          </Link> 

          <Link to="/forest">
            Forest
          </Link> 
          <Link to="/coast">
            Coast
          </Link> 
        </Dropdown>

        <Link to="/rectangles">Rectangles</Link>
      </Navbar>
    </div>
  )
}
export default Menu