import React, {useState, useEffect} from 'react';
 
const  Home = props => {

  return(
      <div className="home">
        <div className="text">
          <div className="social">
            <h4>Connected Reasoning</h4>
          </div>
          <div className="rw-sentence">
            <div className="rw-words rw-words-1">
              <span>Fractals</span>
              <span>Mapping</span>
              <span>Animation</span>
              <span>Visualization</span>
              <span>Databses</span>
            </div>
          </div>
        </div>
      </div>
  );
}
export default Home
