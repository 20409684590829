import React, {useState, useEffect} from 'react';
import { Stage, Layer, Rect } from 'react-konva';
import {Button} from 'react-materialize';
import Konva from 'konva';
import { v4 as uuidv4 } from 'uuid';
import Tree from './tree';
import '../../styles/main.scss';
 

const  Forest = (props) => {

  useEffect(() => {

  },[]);
  const stageRef = React.useRef();
  const downloadURI = (uri, name) => {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link = null;
  }
  const download = () => {
    const theCanvas = document.getElementsByTagName("canvas");
    var dataURL = theCanvas[0].toDataURL({ pixelRatio: 3 });
    downloadURI(dataURL, 'stage.png');
  }
  return (
    <div className="forest">
      <Button node="button" style={{ marginRight: '5px' }} waves="light" onClick={download}>Download</Button>
      <Stage width={window.innerWidth} height={window.innerHeight-100}  ref={stageRef}  >
        <Layer>
          <Rect 
            x={0}
            y={0}
            width={window.innerWidth}
            height={window.innerHeight-100}
            fillLinearGradientStartPoint={{ x: window.innerWidth/2, y: 0 }}
            fillLinearGradientEndPoint={{ x:window.innerWidth/2 , y: window.innerHeight }}
            fillLinearGradientColorStops={[0, '#589be8',.60, '#5ec6e8', .64, '#705b26', 1, '#6c9435']}
          />
          {
            [...Array(5)].map((e, i) => {
              return <Tree key={uuidv4()} id={i+1} />
            })
          }
        </Layer>
      </Stage>
    </div>
  )
}
export default Forest;