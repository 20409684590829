import React, {useState, useEffect} from 'react';
import { Stage, Layer, Line } from 'react-konva';
import Konva from 'konva';
import '../../styles/main.scss';
import { v4 as uuidv4 } from 'uuid';


const  Sierpinski = (props) => {
  const [triangles, setTriangles] = useState([]);

  useEffect(()=>{
    drawTriangle(4,[],0.5, 0,  window.innerWidth/2, 50);
  },[]);
  const masterScale = window.innerWidth * 0.55;
  const drawTriangle = (depth, trianglesTemp, scale, angle, translateX, translateY) => {
    if(depth === 0 ){
      angle += -Math.PI * (1/2);
      let x1 = Math.floor(Math.cos(angle)  * scale  * masterScale) + translateX;
      let y1 = Math.floor(Math.sin(angle)  * scale  * masterScale) + translateY;
      angle += Math.PI * (2/3);
      let x2 = Math.floor(Math.cos(angle)  * scale  * masterScale) + translateX;
      let y2 = Math.floor(Math.sin(angle)  * scale  * masterScale) + translateY;;
      angle += Math.PI * (2/3);
      let x3 = Math.floor(Math.cos(angle)  * scale  * masterScale) + translateX;;
      let y3 = Math.floor(Math.sin(angle)  * scale  * masterScale) + translateY;;
      let x4 = x1;
      let y4 = y1;
      trianglesTemp.push( {points: [x1, y1, x2, y2, x3,y3, x4, y4], color:'black'});
      //console.log('in triangles', trianglesTemp.length);
      setTriangles([...trianglesTemp]);

    } else{

      drawTriangle(
        depth-1, 
        trianglesTemp,  
        scale * 0.5, 
        0, 
        translateX, 
        translateY);
      drawTriangle(
        depth-1, 
        trianglesTemp,  
        scale * 0.5, 
        0, 
        translateX + Math.floor(Math.cos(angle)  * scale * 0.45 * masterScale), 
        translateY + Math.floor(Math.cos(angle)  * scale * 0.75 * masterScale));
      drawTriangle(
        depth-1, 
        trianglesTemp,  
        scale * 0.5, 
        0, 
        translateX - Math.floor(Math.cos(angle)  * scale * 0.45 * masterScale), 
        translateY + Math.floor(Math.cos(angle)  * scale * 0.75 * masterScale));
      

    }
    
  }


  
  return (
    <div className="lines canvas" >
      <Stage width={window.innerWidth} height={window.innerHeight-70}  >
          <Layer >
          { 
            triangles.map(triangle =>{
              //console.log('triangle is ', triangle);
              return(
                <Line 
                  key={uuidv4()} 
                  x={0}
                  y={0}
                  points={triangle.points}
                  fill={triangle.color}

                  closed
                />)
              })
            
          }
        </Layer>
      </Stage>
    </div>
  )
}
export default Sierpinski;