export default function OrthoSketch (p) {

  const screenHeight = window.innerHeight-60;
  const screenWidth = window.innerWidth;

  let x = 100;
  let y = 100;
  let angle1 = 0.0;
  let segLength = 50;

  p.setup = function () {
    p.createCanvas(screenWidth, screenHeight);
    p.strokeWeight(20.0);
    p.stroke(255, 100);
  };
  p.myCustomRedrawAccordingToNewPropsHandler = function (props) {
    console.log('props is ', props);

  };

  p.draw = function () {
    p.background(0);

    let dx = p.mouseX - x;
    let dy = p.mouseY - y;
    angle1 = p.atan2(dy, dx);
    x = p.mouseX - p.cos(angle1) * segLength;
    y = p.mouseY - p.sin(angle1) * segLength;

    segment(x, y, angle1);
    p.ellipse(x, y, 20, 20);


  };

  function segment(x, y, a) {
    p.push();
    p.translate(x, y);
    p.rotate(a);
    p.line(0, 0, segLength, 0);
    p.pop();
  }
}