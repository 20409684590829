import React, {useState, useEffect} from 'react';
import {Button} from 'react-materialize';
import {Stage, Layer, Circle } from 'react-konva';
import Konva from 'konva';
import '../../styles/main.scss';
import { v4 as uuidv4 } from 'uuid';
 
const  Chaos = props => {
  const [points, setPoints] = useState([]);
  let pointsTemp = [];

  useEffect(() => {
    const maxDepth = 10000;
    console.log(window.innerWidth)
    pointsTemp.push({x:Math.floor(window.innerWidth * 4/16), y: Math.floor(window.innerHeight * 1/6), radius:10, color:0});
    pointsTemp.push({x:Math.floor(window.innerWidth * 1/16), y: Math.floor(window.innerHeight * 5/6), radius:10, color:1});
    pointsTemp.push({x:Math.floor(window.innerWidth * 8/16), y: Math.floor(window.innerHeight * 5/6), radius:10, color:2});
    iterate();


  },[]);

  const stageRef = React.useRef();

  const colors = [
    "#cc0000",
    "#00cc00",
    "#0000cc",
  ];

  const iterate = () => {   
    let newPointSet = [];
    //if(depth === 0  ){
    //  setPoints([...pointsTemp])
    //} else{
      /*
      for(let i = 0; i < 0 ; i++){
        //console.log('color is ', i%3);
        const x = Math.random() * window.innerWidth/2;
        const y = Math.random() * window.innerWidth/2;
        const radius = 10;
        const newPoint ={x:x, y:y, radius:10, color:i%3};
        pointsTemp.push(newPoint); 
        //iterate(depth - 1, newPoint) ;
      }
      // */
      setPoints([...pointsTemp])
    //}
  }
  
  const downloadURI = (uri, name) => {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link = null;
  }
  
  const download = () => {
    //const theCanvas = document.getElementsByTagName("canvas");
    //var dataURL = theCanvas[0].toDataURL({ pixelRatio: 3 });
    //adownloadURI(dataURL, 'chaos.png');
  }

  return( 
    <div className="canvas chaos">
      <Button node="button" style={{ marginRight: '5px' }} waves="light" onClick={download}>Download</Button>
      <Stage className="stager" width={window.innerWidth} height={window.innerHeight-100}  ref={stageRef}  >
        <Layer>
        {
          points.map((point) =>{
            return (
              <Circle 
                key={uuidv4()} 
                x={point.x}
                y={point.y}
                radius ={point.radius}
                fill={colors[point.color]}
                stroke={colors[point.color]}
              />

            )
          })
        }
        </Layer>
      </Stage>
    </div>  
  )
}
export default Chaos;