import React, {useState, useEffect} from 'react';
import { Stage, Layer, Circle } from 'react-konva';
import Konva from 'konva';
import '../../styles/main.scss';
import { v4 as uuidv4 } from 'uuid';
 

const  SimpleFractal = (props) => {
  const [items, setItems] = useState([]);
  const itemsTemp = []

  useEffect(()=>{
    const item = {x:window.innerWidth/2, y:window.innerHeight/2 +25 , r:100, c:0};
    iterate(5,item, .75, 0, 0);  
  },[]);
  
  const angles = [
      Math.PI * 5/6,                  //   0 : 0 PI
      Math.PI * 1/6,  // 120 : 2/3 PI
      Math.PI * 3/2    // 240 : 4/3 PI
    ];
  
  const colors = [
    "#0081E5",
    "#07D2E2",
    "#0FE0A3",
    "#17DD5B",
    "#22DB1E",
    "#6AD925",
    "#ACD62C",
    "#D4C133",
    "#D1893A",
    "#CF5640"
  ];
  
  const iterate = (depth, item, scale, colorIndex, rotation) => {   
    //console.log('in iterate depth:' + depth + ', scale: '+scale);
    let newItemSet = [];
    if(depth === 0 ){
      setItems([...itemsTemp]);
    } else{
      const color = Konva.Util.getRandomColor();
      for(let i = 0; i< 3; i++){
        const angle = angles[i];
        const x = item.x + Math.cos(angle+rotation)  * item.r * 1.55;
        const y = item.y + Math.sin(angle+rotation)  * item.r * 1.55;
        const r = item.r * scale;
        const newItem = {x:x, y:y, r:r, c:depth};
        itemsTemp.push(item);
        iterate(depth - 1, newItem, scale * .75, color, rotation);
      }
    }
  }

  return (
    <div className="lines canvas" >
      <Stage width={window.innerWidth} height={window.innerHeight-70}  >
          <Layer >
          {console.log('items.length is ', items.length)}
          { 
            items.map((item, i) =>{
              console.log('colors[item.c] is ', colors[item.c]);
              return(
                <Circle 
                  key={uuidv4()} 
                  x={item.x}
                  y={item.y}
                  radius={item.r}
                  opacity="0.6"
                  fill={colors[item.c]}
                />
              )
            })
          }
        </Layer>
      </Stage>
    </div>
  )
}
export default SimpleFractal;