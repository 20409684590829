import React, {useState, useEffect} from 'react';
import { Stage, Layer, Line } from 'react-konva';
import Konva from 'konva';
import '../../styles/main.scss';
import { v4 as uuidv4 } from 'uuid';


const  Koch = (props) => {
  const [kochLines, setKochLines] = useState([]);

  useEffect(()=>{
    draw(3);
  },[]);
  const masterScale = window.innerWidth * 0.6;
  const draw = (maxDepth) =>{
    const p0 = {x:window.innerWidth * 0.1, y: window.innerHeight * 0.75};
    const p1 = {x:window.innerWidth * 0.9, y: window.innerHeight * 0.75};
    iterate(p0, p1, [], maxDepth);
  };
  const iterate = (p0, p1, kochLinesTemp, depth ) => {   
    const dx = p1.x - p0.x;
    const dy = p1.y - p0.y;
    const dist = Math.sqrt((dx*dx)+(dy*dy));
    const unit = dist/3;
    const angle = Math.atan2(dy,dx);
    const pa = {x:p0.x + Math.cos(angle) * unit, y:p0.y + Math.sin(angle) * unit}
    const pb = {x:pa.x + Math.cos(angle-(Math.PI/3)) * unit, y:pa.y + Math.sin(angle-(Math.PI/3)) * unit};
    const pc = {x:p0.x + Math.cos(angle) * unit * 2, y:p0.y + Math.sin(angle) * unit * 2}

    if(depth === 0 ){
      kochLinesTemp.push( {points: [p0.x, p0.y, pa.x, pa.y, pb.x, pb.y, pc.x, pc.y, p1.x ,p1.y]});
      setKochLines([...kochLinesTemp]);
    } else{
      iterate(p0, pa, kochLinesTemp, depth-1);
      iterate(pa, pb, kochLinesTemp, depth-1);
      iterate(pb, pc, kochLinesTemp, depth-1);
      iterate(pc, p1, kochLinesTemp, depth-1);
    }
  }
  return (
    <div className="lines canvas" >
      <Stage width={window.innerWidth} height={window.innerHeight-70}  >
          <Layer >
          { 

            kochLines.map(kochLine =>{
              //console.log('triangle is ', triangle);
              return(
                <Line 
                  key={uuidv4()} 
                  x={0}
                  y={0}
                  points={kochLine.points}
                  stroke={Konva.Util.getRandomColor()}
                  strokeWidth="4"
                />
              )
            })
          }
        </Layer>
      </Stage>
    </div>
  )
}
export default Koch;