export default function sketch (p) {

  const screenHeight = window.innerHeight-60;
  const screenWidth = window.innerWidth;

 let diameter;

  p.setup = function () {
    p.createCanvas(screenWidth, screenHeight);
  };
  p.myCustomRedrawAccordingToNewPropsHandler = function (props) {
    console.log('props is ', props);
    diameter = parseInt(props.diameter);
  };

  p.draw = function () {
    if (p.mouseIsPressed) {
      p.fill(0);
    } else {
      p.fill(255);
    }
    p.ellipse(p.mouseX, p.mouseY, diameter, diameter);
  };
}