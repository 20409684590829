import React, {useState, useEffect} from 'react';
import { Stage, Layer, Line } from 'react-konva';
import Konva from 'konva';
import '../../styles/main.scss';
import { v4 as uuidv4 } from 'uuid';


const  Lines = (props) => {
  const [lineSegments, setLineSegments] = useState([]);
  const space = window.innerWidth/10;
  const height = window.innerHeight -70;
  const widths = [0, window.innerWidth/2, window.innerWidth];

  useEffect(()=>{

  },[]);
  const lines = [];
  for(var i = 0; i <=500; i++){
    lines.push( {x1: Math.random() * (window.innerWidth) , y1:Math.random() * (window.innerHeight), x2: Math.random() * (window.innerWidth), y2: Math.random() * (window.innerHeight)});
  }
  return (
    <div className="lines canvas" >
      <Stage width={window.innerWidth} height={window.innerHeight-70}  >
          <Layer >
          {
            lines.map(line =>{
              return(
                <Line 
                  key={uuidv4()} 
                  x={0}
                  y={0}
                  points={[line.x1,line.y1, line.x2, line.y2]}
                  stroke={Konva.Util.getRandomColor()}
                  strokeWidth="3"
                />)
              })
          }
        </Layer>
      </Stage>
    </div>
  )
}
export default Lines;