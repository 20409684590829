import React, {useState, useEffect} from 'react';
import {Layer, Line } from 'react-konva';
import Konva from 'konva';
import '../../styles/main.scss';
import { v4 as uuidv4 } from 'uuid';
 
const  Tree = props => {
  const [lines, setLines] = useState([]);
  let itemsTemp = [];

  useEffect(() => {
    const maxDepth = 9;
    const startX =  Math.floor(window.innerWidth * (props.id/6));
    const startY =  Math.floor((window.innerHeight * 12/16) + Math.random() * 150);
    console.log('startX is ' + startX + ' startY is ' + startY);
    const localLine = {points:[startX, startY, startX, startY-150], color:maxDepth, scale:.7};
    itemsTemp.push(localLine);
    const angle = -Math.PI * 1/2;
    iterate(maxDepth,localLine, angle, 0.6);
    setLines([...itemsTemp]);

  },[]);

    const colors = [
      "#056E00",
      "#338a00",
      "#056E00",
      "#577001",
      "#307019",
      "#274001",
      "#004019",
      "#274001",
      "#4F4B36",
      "#2D2812",
      "#403014",
      "#1C1701",
  ];

  const iterate = (depth, item,angle, scale) => {   

    let newLineSet = [];
    if(depth === 0  ){
      setLines([...itemsTemp])
    } else{
      for(let i = 0; i < 2 ; i++){
        const newAngle =i===0 ? angle + Math.PI *1/4*Math.random()  : angle -Math.PI * 1/4* Math.random();
        const x1 = item.points[2];
        const y1 = item.points[3];
        const x2 = item.points[2] + (Math.cos(newAngle) * 150 * scale);
        const y2 = item.points[3] + (Math.sin(newAngle) * 150 * scale);

        const newItem ={points:[x1,y1,x2,y2], color:depth, scale:scale};
        itemsTemp.push(newItem); 
        iterate(depth - 1, newItem, newAngle, scale * .3 + Math.random()*.2 ) ;
      }
    }
  }
  
  return( 

  lines.map((l) =>{
    return (

        <Line 
          key={uuidv4()} 
          x={0}
          y={0}
          points={l.points}
          stroke={colors[l.color]}
          strokeWidth={4 + 4 * l.scale}

        />

    )
    })
  )
}
export default Tree;