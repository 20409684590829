import React from 'react';
import { Rect } from 'react-konva';


const  ColoredRect = (props) =>  {
  return (
    <Rect
      x={ props.rectangle.x * (window.innerWidth - 200)}
      y={ props.rectangle.y * (window.innerHeight - 200)}
      width={20 +  props.rectangle.width * 200}
      height={ 20 + props.rectangle.height * 200 }
      fill={props.rectangle.color}
      shadowBlur={2}
      onClick={props.handleClick} 
      onTap={props.handleClick}
    />
  );
}
export default ColoredRect;
