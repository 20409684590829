import React from 'react';

const About  = () => (
  <div className="about p-a-30">
    <h1>
      About Goes Here
    </h1>
  </div>
)

export default About